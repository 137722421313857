import { useCallback, useState } from 'react';
import { canUseDOM } from 'exenv';

import { usePolyfill } from '../../../hooks/usePolyfill';

const useWithIntersectionObserver = () => {
  const detectIntersectionObserver = useCallback(() => {
    if (!canUseDOM) {
      return;
    }
    return typeof window.IntersectionObserver !== 'undefined';
  }, []);
  const [supportsIntersectionObserver, setSupportsIntersectionObserver] = useState(
    detectIntersectionObserver()
  );
  const load = useCallback(
    () =>
      import(
        /* webpackChunkName: "intersection-observer-polyfill" */
        'intersection-observer'
      ),
    []
  );
  const callback = useCallback(() => setSupportsIntersectionObserver(true));
  usePolyfill(detectIntersectionObserver, load, callback);

  return { supportsIntersectionObserver };
};

export default useWithIntersectionObserver;
