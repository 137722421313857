import classnames from 'classnames';
import { canUseDOM } from 'exenv';
import throttle from 'lodash/throttle';
import { arrayOf, bool, node, number, oneOfType, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import SplitType from 'split-type';

import { DURATION } from '../config';
import useWithIntersectionObserver from '../hooks/useWithIntsersectionObserver';

const CLASS_BASE = 'text-animation';
const CLASS_VISIBLE = `${CLASS_BASE}--is-visible`;
const OPTIONS = {
  types: 'lines',
  tagName: 'span',
};

/**
 * A component that splits the given text (string) as children into several
 * lines of <span> elements that are animated.
 *
 * By default this component renders as a `<span>`. By defining the `as` prop,
 * it is possible to change the HTMLElement type.
 *
 * The props `duration` and `delay` are passing the given values in milliseconds
 * into corresponding CSS-variable that can be used to contol the timing of the
 * animation.
 *
 * By default, this component triggers its animation on it's own. If this should
 * be controlled by an outer logic, it's possible to deactivate this behavior by
 * setting `useObserver={false}`. The visibility state can be controlled from
 * the outside using the prop `isVisible`. The components of <GroupAnimation>
 * are using this approach.
 */
const TextAnimation = ({
  children,
  className,
  as: As,
  duration,
  delay,
  useObserver,
  isVisible,
}) => {
  const { supportsIntersectionObserver } = useWithIntersectionObserver();
  const [inView, setInView] = useState(false);
  const onChange = useCallback((state) => state && setInView(true), [setInView]);

  const ref = useRef(null);

  useEffect(() => {
    if (!canUseDOM) {
      return () => undefined;
    }

    const split = new SplitType(ref.current, OPTIONS);

    const animate = () => {
      split.lines.forEach((line, index) => {
        line.style.setProperty('--text-animation-index', index);
      });
    };

    const onResize = throttle(() => {
      split.revert();
      split.split();
      animate();
    }, 100);

    animate();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      split.revert();
    };
  }, []);

  const Element = useMemo(() => {
    if (!useObserver || !supportsIntersectionObserver) {
      return As;
    }

    return ({ children: childs, ...props }) => (
      <InView triggerOnce onChange={onChange}>
        {({ ref: forward }) => (
          <As
            {...props}
            ref={(node) => {
              forward(node);
              ref.current = node;
            }}
          >
            {childs}
          </As>
        )}
      </InView>
    );
  }, [useObserver, supportsIntersectionObserver, As, onChange]);

  const classes = useMemo(() => {
    const modifier = { [CLASS_VISIBLE]: isVisible || inView };
    return classnames(className, CLASS_BASE, modifier);
  }, [className, isVisible, inView]);

  const styles = useMemo(
    () => ({
      '--text-animation-duration': `${duration}ms`,
      '--text-animation-delay': `${delay}ms`,
    }),
    [duration, delay]
  );

  return (
    <Element ref={ref} className={classes} style={styles}>
      {children}
    </Element>
  );
};

TextAnimation.defaultProps = {
  as: 'span',
  className: undefined,
  duration: DURATION,
  delay: 0,
  useObserver: true,
  isVisible: false,
};

TextAnimation.propTypes = {
  as: string,
  className: string,
  children: oneOfType([arrayOf(string), string, arrayOf(node), node]).isRequired,
  duration: number,
  delay: number,
  useObserver: bool,
  isVisible: bool,
};

export default TextAnimation;
