import classnames from 'classnames';
import { arrayOf, bool, node, number, oneOfType, string } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import InView from 'react-intersection-observer';

import { DURATION } from '../config';
import useWithIntersectionObserver from '../hooks/useWithIntsersectionObserver';

/**
 * A wrapper component that triggers an animation by the change of the
 * visibility based on the positon of the users viewport. This changes the base
 * class by adding a modifier `--is-visible` to the class list. There are several
 * predefined mixins that can be used with this component in CSS:
 *
 * * @include block-animation-fade-in();
 * * @include block-animation-fade-in-slide-left()
 * * @include block-animation-fade-in-slide-right()
 * * @include block-animation-fade-in-slide-up()
 * * @include block-animation-fade-in-slide-down()
 *
 * It's also possible to build a custom animation just by react on the `--is-visible`
 * modifier in CSS.
 *
 * By default this component renders as a `<div>`. By defining the `as` prop,
 * it is possible to change the HTMLElement type.
 *
 * The props `duration` and `delay` are passing the given values in milliseconds
 * into corresponding CSS-variable that can be used to contol the timing of the
 * animations.
 *
 * By default, this component triggers its animation on it's own. If this should
 * be controlled by an outer logic, it's possible to deactivate this behavior by
 * setting `useObserver={false}`. The visibility state can be controlled from
 * the outside using the prop `isVisible`. The components of <GroupAnimation>
 * are using this approach.
 */
const BlockAnimation = ({
  children,
  className,
  as,
  duration,
  delay,
  useObserver,
  isVisible,
  ...props
}) => {
  const { supportsIntersectionObserver } = useWithIntersectionObserver();
  const [inView, setInView] = useState(false);
  const onChange = useCallback((state) => state && setInView(true), [setInView]);

  const Element = useMemo(() => {
    if (!useObserver || !supportsIntersectionObserver) {
      return as;
    }

    return ({ children: childs, ...rest }) => (
      <InView triggerOnce as={as} onChange={onChange} {...rest}>
        {childs}
      </InView>
    );
  }, [as, supportsIntersectionObserver, onChange, useObserver]);

  const classes = useMemo(() => {
    const [base, ...rest] = className.split(' ').filter((name) => name.trim() !== '');
    const modifier = { [`${base}--is-visible`]: isVisible || inView };
    return classnames(base, modifier, rest);
  }, [className, isVisible, inView]);

  const styles = useMemo(
    () => ({
      '--block-animation-duration': `${duration}ms`,
      '--block-animation-delay': `${delay}ms`,
      ...props.style,
    }),
    [duration, delay, props.styles]
  );

  return (
    <Element className={classes} style={styles}>
      {children}
    </Element>
  );
};

BlockAnimation.defaultProps = {
  as: 'div',
  className: 'block-animation',
  duration: DURATION,
  delay: 0,
  useObserver: true,
  isVisible: false,
};

BlockAnimation.propTypes = {
  as: string,
  className: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
  duration: number,
  delay: number,
  useObserver: bool,
  isVisible: bool,
};

export default BlockAnimation;
