import { arrayOf, node, oneOfType, string } from 'prop-types';
import React, { createContext } from 'react';

import BlockAnimation from '../block-animation/block-animation';
import TextAnimation from '../text-animation/text-animation';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';

const copyWithout = (source = {}, removeKeys = []) => {
  Object.entries(source).reduce((target, [key, value]) => {
    if (removeKeys.includes(key)) {
      return target;
    }

    return { ...target, [key]: value };
  }, {});
};

const Context = createContext();

/**
 * Creates a stackable group of animations. This is the root component for child
 * components that are wrapped by <GroupedBlockAnimation>. When this components
 * get's visible by the user, it will trigger the animations in all child
 * components that uses <GroupedBlockAnimation>.
 */
export const GroupAnimation = ({ children, as: As, ...props }) => {
  const [ref, inView] = useIntersectionObserver({ triggerOnce: true });

  return (
    <As {...props} ref={ref}>
      <Context.Provider value={inView}>{children}</Context.Provider>
    </As>
  );
};

GroupAnimation.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  as: string,
};

GroupAnimation.defaultProps = {
  as: 'div',
};

/**
 * A component that behaves like a <BlockAnimation> component, but is controlled
 * by the root component <GroupAnimation>. When the parent <GroupAnimation>
 * get's visible by the user, it will trigger the animation within this component.
 *
 * See <BlockAnimation> how to use animations.
 */
export const GroupedBlockAnimation = ({ children, ...props }) => (
  <Context.Consumer>
    {(isVisible) => (
      <BlockAnimation {...props} isVisible={isVisible} useObserver={false}>
        {children}
      </BlockAnimation>
    )}
  </Context.Consumer>
);

// Inherit from BlockAnimation but remove explicitly defined props by implementation:
GroupedBlockAnimation.propTypes = {
  ...copyWithout(TextAnimation.propTypes, ['useObserver', 'isVisible']),
};

GroupedBlockAnimation.defaultProps = {
  ...copyWithout(TextAnimation.defaultProps, ['useObserver', 'isVisible']),
};

/**
 * A component that behaves like a <TextAnimation> component, but is controlled
 * by the root component <GroupAnimation>. When the parent <GroupAnimation>
 * get's visible by the user, it will trigger the animation within this component.
 *
 * See <TextAnimation> how to use animations.
 */
export const GroupedTextAnimation = ({ children, ...props }) => (
  <Context.Consumer>
    {(isVisible) => (
      <TextAnimation {...props} isVisible={isVisible} useObserver={false}>
        {children}
      </TextAnimation>
    )}
  </Context.Consumer>
);

// Inherit from TextAnimation but remove explicitly defined props by implementation:
GroupedBlockAnimation.propTypes = {
  ...copyWithout(GroupAnimation.propTypes, ['useObserver', 'isVisible']),
};

GroupedBlockAnimation.defaultProps = {
  ...copyWithout(GroupAnimation.defaultProps, ['useObserver', 'isVisible']),
};
